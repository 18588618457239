import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-semibold flex flex-row items-center" }
const _hoisted_2 = {
  key: 0,
  class: "rounded font-normal text-xs px-1 text-12px bg-green-lp-100 text-white ml-3"
}
const _hoisted_3 = { class: "text-red-lp-300 font-normal pl-1" }
const _hoisted_4 = {
  key: 1,
  class: "relative"
}
const _hoisted_5 = { class: "text-black-lp-300 w-full flex flex-col text-12px" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "py-2 text-gray-lp-500 font-normal" }
const _hoisted_8 = { class: "text-gray-lp-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _directive_sanitize_html = _resolveDirective("sanitize-html")!

  return (_openBlock(), _createBlock("div", {
    class: `${_ctx.customClass} text-${_ctx.fontSize}px xxl:text-${_ctx.fontSize + 2}px`
  }, [
    _createVNode("div", {
      class: 
        `${_ctx.borderFailed &&
          'border border-red-lp-100 border-dashed rounded'} ${_ctx.padded &&
          'pt-2 px-3'}`
      
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.chip !== '')
            ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.chip), 1))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode("span", { class: "text-gray-lp-500 font-normal" }, " (" + _toDisplayString(_ctx.suffix || (_ctx.optional && _ctx.$t('optional'))) + ")", 513), [
            [_vShow, !!_ctx.suffix || _ctx.optional]
          ]),
          _withDirectives(_createVNode("span", _hoisted_3, "*", 512), [
            [_vShow, _ctx.asterisk]
          ]),
          (!!_ctx.tooltipDescription)
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode("img", {
                  src: 
                require(`@/app/ui/assets/svg/${
                  _ctx.isActiveTooltip ? 'info-red' : 'info'
                }.svg`)
              ,
                  class: "ml-1 h-4 xxl:h-5 cursor-pointer",
                  alt: "info",
                  onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTooltip($event))),
                  onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleTooltip($event)))
                }, null, 40, ["src"]),
                _createVNode(_component_OverlayPanel, {
                  ref: "opTooltip",
                  appendTo: "body",
                  class: "w-1/5"
                }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_5, [
                      _createVNode("div", _hoisted_6, _toDisplayString(_ctx.tooltipTitle), 1),
                      _withDirectives(_createVNode("div", _hoisted_7, null, 512), [
                        [_directive_sanitize_html, _ctx.tooltipDescription]
                      ])
                    ])
                  ]),
                  _: 1
                }, 512)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode("p", {
          class: 
            _ctx.customStyleValue ? _ctx.customStyleValue : 'my-1 font-normal break-words'
          
        }, _toDisplayString(!_ctx.isDefault ? "" : _ctx.value || "-"), 3),
        _createVNode("div", _hoisted_8, _toDisplayString(_ctx.caption), 1)
      ])
    ], 2)
  ], 2))
}