
import { Options, prop, Vue } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";

class Props {
  value = prop<string>({
    default: ""
  });
  customClass = prop<string>({
    default: "text-gray-lp-300",
    type: String
  });
  customStyleValue = prop<string>({
    default: ""
  });
  caption = prop<string>({
    default: "",
    type: String
  });
  label = prop<string>({
    default: "",
    type: String
  });
  fontSize = prop<number>({
    default: 12,
    type: Number
  });
  optional = prop<boolean>({
    default: false,
    type: Boolean
  });
  suffix = prop<string>({
    default: "",
    type: String
  });
  tooltipDescription = prop<string>({
    default: "",
    type: String
  });
  tooltipTitle = prop<string>({
    default: "",
    type: String
  });
  asterisk = prop<boolean>({
    default: false,
    type: Boolean
  });
  borderFailed = prop<boolean>({
    default: false,
    type: Boolean
  });
  padded = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDefault = prop<boolean>({
    default: true,
    type: Boolean
  });
  chip = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  components: {
    OverlayPanel
  }
})
export default class DataWrapper extends Vue.with(Props) {
  isActiveTooltip = false;
  toggleTooltip(event: any) {
    this.isActiveTooltip = !this.isActiveTooltip;
    const refs: any = this.$refs;
    refs["opTooltip"].toggle(event);
  }
}
